// src/app/classes/TiktokPixelClass.js
export class TiktokPixelClass {
  static load() {
    if (typeof window !== "undefined") {
      // Wrap the TikTok Pixel code in a function to avoid no-unused-expressions
      const initTiktokPixel = () => {
        window.TiktokAnalyticsObject = "ttq";
        window.ttq = window.ttq || [];
        const ttq = window.ttq;

        // Define methods
        ttq.methods = [
          "page",
          "track",
          "identify",
          "instances",
          "debug",
          "on",
          "off",
          "once",
          "ready",
          "alias",
          "group",
          "enableCookie",
          "disableCookie",
          "holdConsent",
          "revokeConsent",
          "grantConsent",
        ];

        // Helper function to defer method calls
        ttq.setAndDefer = function (target, method) {
          target[method] = function () {
            target.push(
              [method].concat(Array.prototype.slice.call(arguments, 0))
            );
          };
        };

        // Initialize methods
        for (let i = 0; i < ttq.methods.length; i++) {
          ttq.setAndDefer(ttq, ttq.methods[i]);
        }

        // Instance helper
        ttq.instance = function (id) {
          const instance = ttq._i[id] || [];
          for (let n = 0; n < ttq.methods.length; n++) {
            ttq.setAndDefer(instance, ttq.methods[n]);
          }
          return instance;
        };

        // Load the TikTok Pixel script
        ttq.load = function (id, config) {
          const scriptUrl = "https://analytics.tiktok.com/i18n/pixel/events.js";
          ttq._i = ttq._i || {};
          ttq._i[id] = [];
          ttq._i[id]._u = scriptUrl;
          ttq._t = ttq._t || {};
          ttq._t[id] = +new Date();
          ttq._o = ttq._o || {};
          ttq._o[id] = config || {};

          const script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;
          script.src = `${scriptUrl}?sdkid=${id}&lib=ttq`;

          const firstScript = document.getElementsByTagName("script")[0];
          firstScript.parentNode.insertBefore(script, firstScript);
        };

        // Load the TikTok Pixel
        ttq.load("CTVVJ43C77U1O4685RJ0");
        ttq.page();
      };

      // Call the initialization function
      initTiktokPixel();
    }
  }

  static trackEvent(eventName, data = {}) {
    if (typeof window !== "undefined" && window.ttq) {
      window.ttq.track(eventName, data);
    }
  }
}

// Assuming the necessary files and configurations are correctly set up in your project
import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import "../index.scss"; // Ensure your CSS variables are defined here or in an imported file
import RouteIndex from "./route"; // Your routing component
import reportWebVitals from "../reportWebVitals";
import store from "./redux/store"; // Your Redux store
import { createTheme, ThemeProvider } from "@mui/material";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { SkeletonTheme } from "react-loading-skeleton";
import GoogleAnalyticsSingleton from "./classes/GoogleAnalytics"; // Google Analytics setup
import FacebookPixelSingleton from "./classes/FacebookPixel"; // Facebook Pixel setup
import { IS_PRODUCTION } from "../config"; // Production check
import { getCSSVariableValue } from "./classes/Helpers";

// Initialize Google Analytics and Facebook Pixel
GoogleAnalyticsSingleton.initialize();
FacebookPixelSingleton.initialize();

const App = () => {
  const [muiTheme, setMuiTheme] = useState(createTheme());
  const [chakraTheme, setChakraTheme] = useState(extendTheme());

  useEffect(() => {
    // Dynamically create the MUI theme
    const dynamicMuiTheme = createTheme({
      typography: {
        fontFamily: "Poppins",
      },
      background: {
        default: getCSSVariableValue("--primary-color"),
      },
      palette: {
        mode: getCSSVariableValue("--color-mode"),
        primary: {
          main: getCSSVariableValue("--background-color-inverse"),
          light: getCSSVariableValue("--primary-color"),
          dark: getCSSVariableValue("--primary-color"),
          contrastText: getCSSVariableValue("--background-color-inverse"),
        },
        text: {
          primary: getCSSVariableValue("--background-color-inverse"),
        },
        secondary: {
          main: getCSSVariableValue("--primary-color"),
        },
      },
    });

    // Dynamically create the Chakra theme
    const dynamicChakraTheme = extendTheme({
      parts: ["field", "icon"],
      baseStyle: {
        icon: {
          marginRight: "6px",
          color: "#000",
        },
      },
    });

    setMuiTheme(dynamicMuiTheme);
    setChakraTheme(dynamicChakraTheme);
  }, []);

  // Insert Lucky Orange script if in production
  useEffect(() => {
    if (IS_PRODUCTION) {
      const script = document.createElement("script");
      script.src = "https://tools.luckyorange.com/core/lo.js?site-id=5e940fc9";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <Provider store={store}>
      <SkeletonTheme>
        <ChakraProvider theme={chakraTheme}>
          <ThemeProvider theme={muiTheme}>
            <RouteIndex />
          </ThemeProvider>
        </ChakraProvider>
      </SkeletonTheme>
    </Provider>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<App />);

// Optionally include your reportWebVitals setup here
reportWebVitals();

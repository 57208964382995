import "./index.scss";
import { Footer } from "../../../components";
import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import HeaderContainer from "../../LandingPage/HeaderContainer";
import Testimonials from "../../LandingPage/Testimonials";
import APIConnection from "../../../api";
import { MetaTags } from "react-meta-tags";
import MainContainer from "./MainContainer";
import SoundContainer from "./SoundContainer";
import TopBar from "../../../components/TopBar";
import GoogleAnalyticsSingleton from "../../../classes/GoogleAnalytics";
import ReactPlayer from "react-player";
import { Switch } from "@mui/material";
import {
  CAMPAIGN_CODE,
  CAMPAIGN_CODE_ANNUALLY,
  CAMPAIGN_CODE_MONTHLY,
} from "../../../../config";
import CountDownTimer from "../../../components/CountDownTimer";
import { stripePayments } from "../../../../assets/images";
import { TiktokPixelClass } from "../../../classes/TiktokPixelClass";

class Campaign10GBPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packs: [],
      sounds: [],
      totalSounds: 100,
      totalPacks: 100,
      loading: true,
      isAnnual: false, // Default to annual view
      campaignCode: CAMPAIGN_CODE_ANNUALLY,
    };
  }

  componentDidMount() {
    GoogleAnalyticsSingleton.pageview("campaign-10gb");
    TiktokPixelClass.load();
    this.setPacksUI();
  }

  setPacksUI = async () => {
    let res = await APIConnection.getPacks({ filter: { size: 100 } });
    let res_2 = await APIConnection.getSounds({ filter: { size: 40 } });
    const { message, status } = res;
    if (status !== 200) {
      console.log("res", res);
    } else {
      this.setState({
        loading: false,
        packs: message.data,
        sounds: res_2.message.data,
        totalSounds: res_2.message.total,
        totalPacks: message.total,
      });
    }
  };

  toggleView = () => {
    this.setState((prevState) => {
      const isAnnual = !prevState.isAnnual;
      return {
        isAnnual,
        campaignCode: isAnnual ? CAMPAIGN_CODE_ANNUALLY : CAMPAIGN_CODE_MONTHLY,
      };
    });
  };

  render = () => {
    let campaignCode = this.state.isAnnual
      ? CAMPAIGN_CODE_MONTHLY
      : CAMPAIGN_CODE_ANNUALLY;
    let LINK = `${campaignCode}?prefilled_promo_code=${CAMPAIGN_CODE}`;
    const { isAnnual } = this.state; // Access toggle state
    let now = new Date();

    // Adjust discount and subscription details dynamically
    const discount = !isAnnual ? "87%" : "75%";
    const price = !isAnnual ? 1.97 : 3.97;
    const subscribeText = !isAnnual
      ? "Subscribe for $1.97/mo"
      : "Subscribe for $3.97/mo";
    const { packs, totalPacks, sounds, totalSounds, loading } = this.state;

    return (
      <>
        <>{!packs.length || loading ? <Loading /> : null}</>
        <MetaTags>
          <title>Vertate | 10GB for FREE</title>
          <meta
            name="description"
            content="Sign up and Access our Exclusive Library"
          />
          <meta
            property="og:title"
            content="Vertate | Access our Exclusive Library"
          />
        </MetaTags>
        {/* Header */}
        <TopBar text={"87% DISCOUNT TODAY"} />
        <HeaderContainer justLogo={true} />
        <MainContainer packs={packs} />
        <SoundContainer sounds={sounds} />
        <div className="">
          <h1 className="text-center text-primary title">
            Access a library full of quality sounds
          </h1>
          <h3 className="text-grey text-center container">
            Used by producers worldwide
          </h3>
          <div className="video-container">
            <ReactPlayer
              url="https://t4754943.p.clickup-attachments.com/t4754943/184252a1-3fc5-464d-9dfc-12138279adec/v15044gf0000cu859nfog65ml409vqkg.MP4?view=open"
              controls={true}
              width="100%"
              height="100%"
              style={{ margin: "auto", padding: 0 }}
              onPlay={() =>
                TiktokPixelClass.trackEvent("Listen", {
                  content_name: "Ads Promo",
                  content_type: "video",
                })
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            <div className="subscription-discount-box">
              {/* Toggle Buttons */}
              <div
                style={{
                  display: "flex",
                  gap: "3px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Switch
                  checked={this.state.isAnnual}
                  onChange={this.toggleView}
                  name="subscriptionToggle"
                />
                <p>{this.state.isAnnual ? "Monthly" : "Annual"}</p>
              </div>

              <div className="countdown-box">
                <h3 className="text-grey text-center">{discount} DISCOUNT</h3>
                <h6 className="text-grey"> Offer Ends in </h6>
                <h2 className="inline text-primary">
                  <CountDownTimer
                    id={"promotion-credits_1"}
                    date={
                      new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate(),
                        23,
                        59,
                        59,
                        999
                      )
                    }
                  />
                </h2>
                <h4 className="text-grey-small">{`Hrs : Mins : Secs`}</h4>
              </div>
            </div>

            <a
              href={LINK}
              onClick={() =>
                TiktokPixelClass.trackEvent("Subscribe", {
                  plan: isAnnual ? "Monthly" : "Annual",
                  value: price,
                  currency: "USD",
                })
              }
              className="btn btn-animation"
            >
              <h3>{subscribeText}</h3>
            </a>
            <img
              src={stripePayments}
              style={{ width: 150, margin: "auto", marginTop: 20 }}
            />
          </div>
        </div>
        <Footer width={100} full={false} />
      </>
    );
  };
}

function mapStateToProps(state) {
  const user = state.user;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Campaign10GBPage);

import { Component } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import FastMarquee from "../../../components/FastMarquee";
import { CAMPAIGN_URL } from "../../../../config";
const audioURL = "";
class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { packs, totalSounds } = this.props;
    let LINK = `${CAMPAIGN_URL}`;

    return (
      <div className="landing-page">
        <div className="text-center text-main main-container">
          {/*<div className='flex-container text-center'>
                        <h4 className='tag-outline not-mobile'>100% Royalty Free</h4>
                        <h4 className='tag-outline'>100,000 Sounds</h4>
                        <h4 className='tag-outline'>Unique Sounds</h4>
                    </div>*/}

          <h1 className="title">
            {" "}
            Browse from{" "}
            <strong>
              <CountUp
                enableScrollSpy
                className="h1 text-bold text-primary"
                end={`+${totalSounds}`}
                start={`+${totalSounds - 100}`}
                duration={300}
              />
            </strong>{" "}
            fresh, exclusive sounds.{" "}
          </h1>
          <h3 className="text-grey title " style={{ marginTop: 20 }}>
            The unlimited sound library on the internet.
          </h3>

          <div>
            <Link to={"/signup"} className="btn btn-animation">
              <h3 className="title text-bold ">Browse Samples</h3>
            </Link>
            {/*<h5 className='text-grey text-center' style={{marginTop : 10}}>Start for Free</h5>*/}
          </div>
        </div>

        <div className="margin-container">
          <FastMarquee packs={packs} />
        </div>
        {/*<div className='commu-container'>
                        <CommunityIcon/>
                    </div>*/}
        {/*<div className='text-center container-numbers'>
                    <div className='flex-container text-center'>
                        <div className='number-container container-outline'>
                            <CountUp enableScrollSpy className='h2 text-bold text-primary' end={`+${totalSounds}`} start={`+${totalSounds-100}`}  duration={300}/>
                            <h5 className='text-grey'>Sounds</h5>
                        </div>
                        <div className='number-container container-outline'>
                            <CountUp className='h2 text-bold text-primary' end={`+${totalPacks}`} start={`+${totalPacks-10}`}  duration={1430}/>
                            <h5 className='text-grey'>Packs</h5> 
                        </div>
                    </div>
                </div>*/}
      </div>
    );
  };
}

export default MainContainer;
